import { useMemo } from 'react';
import { Box, Button, VStack, Textarea } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { GroupController, Radio } from 'frontend-components';
import { useStepId, useStore, useSubmitCustomStepForm } from 'frontend-common';

const sending_crypto_assets = 'Will you be sending crypto assets to Keyrock';
const sending_wallet_platform =
  'Platforms on which the wallets for sending crypto assets are held';
const sending_wallet_type = 'Types of wallets - send';
const sending_wallet_address = 'Wallet addresses - send';
const receiving_crypto_assets =
  'Do you plan to receive crypto assets from Keyrock';
const receiving_wallet_platform =
  'Platform on which the receiving wallets are held';
const receiving_wallet_type = 'Types of wallets - receive';
const receiving_wallet_address = 'Wallet addresses - receive';

const validationSchema = Yup.object({
  [sending_crypto_assets]: Yup.string()
    .oneOf(['Yes', 'No'])
    .required()
    .label('This'),
  [sending_wallet_platform]: Yup.string().when(sending_crypto_assets, {
    is: (val: string) => val === 'Yes',
    then: (schema) => schema.label('This').required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [sending_wallet_type]: Yup.string().when(sending_crypto_assets, {
    is: (val: string) => val === 'Yes',
    then: (schema) => schema.label('This').required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [sending_wallet_address]: Yup.string().when(sending_crypto_assets, {
    is: (val: string) => val === 'Yes',
    then: (schema) => schema.label('This').required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [receiving_crypto_assets]: Yup.string()
    .oneOf(['Yes', 'No'])
    .required()
    .label('This'),
  [receiving_wallet_platform]: Yup.string().when(receiving_crypto_assets, {
    is: (val: string) => val === 'Yes',
    then: (schema) => schema.label('This').required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [receiving_wallet_type]: Yup.string().when(receiving_crypto_assets, {
    is: (val: string) => val === 'Yes',
    then: (schema) => schema.label('This').required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [receiving_wallet_address]: Yup.string().when(receiving_crypto_assets, {
    is: (val: string) => val === 'Yes',
    then: (schema) => schema.label('This').required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
});

export const WalletAddresses = () => {
  const stepId = useStepId();
  const { t } = useTranslation();
  const { submitCustomStepForm } = useSubmitCustomStepForm();
  const { metadata } = useStore();

  const defaultValues = useMemo(() => {
    return {
      [sending_crypto_assets]: metadata?.[sending_crypto_assets] || '',
      [sending_wallet_platform]: metadata?.[sending_wallet_platform] || '',
      [sending_wallet_type]: metadata?.[sending_wallet_type] || '',
      [sending_wallet_address]: metadata?.[sending_wallet_address] || '',
      [receiving_crypto_assets]: metadata?.[receiving_crypto_assets] || '',
      [receiving_wallet_platform]: metadata?.[receiving_wallet_platform] || '',
      [receiving_wallet_type]: metadata?.[receiving_wallet_type] || '',
      [receiving_wallet_address]: metadata?.[receiving_wallet_address] || '',
    };
  }, [metadata]);

  const methods = useForm<any>({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    resetField,
    formState: { isValid, isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<any> = async (formData) => {
    submitCustomStepForm({ caseMetadata: formData });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="6" alignItems="start">
        <GroupController
          name={sending_crypto_assets}
          label={t(`steps.${stepId}.${sending_crypto_assets}.label`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Radio
              stepId={stepId}
              name={sending_crypto_assets}
              onChange={(value: string) => {
                resetField(sending_wallet_platform);
                resetField(sending_wallet_type);
                resetField(sending_wallet_address);
                setValue(sending_crypto_assets, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={['Yes', 'No']}
              defaultValue={f.value}
            />
          )}
        />

        {watch(sending_crypto_assets) === 'Yes' && (
          <>
            <GroupController
              name={sending_wallet_address}
              label={t(`steps.${stepId}.${sending_wallet_address}.label`)}
              isRequired={true}
              placeholder="e.g. ERC20: 0xbcTRC20: T9zG21…"
              control={control}
              render={(f) => {
                return (
                  <Textarea
                    maxW="400px"
                    placeholder={`e.g.\nERC20: 0xbc6e...\nTRC20: T9zG...`}
                    resize="vertical"
                    fontSize="smaller"
                    {...f}
                  />
                );
              }}
            />

            <GroupController
              name={sending_wallet_type}
              label={t(`steps.${stepId}.${sending_wallet_type}.label`)}
              helper={t(`steps.${stepId}.${sending_wallet_type}.helper`)}
              isRequired={true}
              control={control}
              render={(f) => {
                return <Textarea maxW="400px" resize="vertical" {...f} />;
              }}
            />

            <GroupController
              name={sending_wallet_platform}
              label={t(`steps.${stepId}.${sending_wallet_platform}.label`)}
              isRequired={true}
              control={control}
              render={(f) => {
                return <Textarea maxW="400px" resize="vertical" {...f} />;
              }}
            />
          </>
        )}

        <GroupController
          name={receiving_crypto_assets}
          label={t(`steps.${stepId}.${receiving_crypto_assets}.label`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Radio
              stepId={stepId}
              name={receiving_crypto_assets}
              onChange={(value: string) => {
                resetField(receiving_wallet_platform);
                resetField(receiving_wallet_type);
                resetField(receiving_wallet_address);
                setValue(receiving_crypto_assets, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={['Yes', 'No']}
              defaultValue={f.value}
            />
          )}
        />

        {watch(receiving_crypto_assets) === 'Yes' && (
          <>
            <GroupController
              name={receiving_wallet_address}
              label={t(`steps.${stepId}.${receiving_wallet_address}.label`)}
              isRequired={true}
              control={control}
              render={(f) => {
                return (
                  <Textarea
                    maxW="400px"
                    placeholder={`e.g.\nERC20: 0xbc6e...\nTRC20: T9zG...`}
                    resize="vertical"
                    fontSize="smaller"
                    {...f}
                  />
                );
              }}
            />

            <GroupController
              name={receiving_wallet_type}
              label={t(`steps.${stepId}.${receiving_wallet_type}.label`)}
              helper={t(`steps.${stepId}.${receiving_wallet_type}.helper`)}
              isRequired={true}
              control={control}
              render={(f) => {
                return <Textarea maxW="400px" resize="vertical" {...f} />;
              }}
            />

            <GroupController
              name={receiving_wallet_platform}
              label={t(`steps.${stepId}.${receiving_wallet_platform}.label`)}
              isRequired={true}
              control={control}
              render={(f) => {
                return <Textarea maxW="400px" resize="vertical" {...f} />;
              }}
            />
          </>
        )}

        <Box>
          <Button
            variant="next"
            isLoading={isSubmitting}
            isDisabled={!isValid}
            type="submit"
          >
            {t('domain.form.next')}
          </Button>
        </Box>
      </VStack>
    </form>
  );
};

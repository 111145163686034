const brandColors = {
  primary: '#3867FF',
  secondary: '#FF7800',
};

const sidebarColors = {
  backgroundColor: '#303052',
  color: '#FFFFFF',
  dotfileLogo: '#FFFFFF',
};

const linkColors = {
  color: '#FF7800',
};

export const tokens = {
  brand: brandColors,
  sidebar: sidebarColors,
  link: linkColors,
};

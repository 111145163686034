import { useMemo } from 'react';
import { Box, Button, VStack, Textarea } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  GroupController,
  Radio,
  Select,
  CountrySelect,
} from 'frontend-components';
import { useStepId, useStore, useSubmitCustomStepForm } from 'frontend-common';

const business_address =
  'If your company operate its business activities from a different address to the company’s registered address, please provide its business address';
const activity = 'Please describe your business activity';
const sensitive_activites =
  'Please indicate if your activities relate to the following area(s)';
const sensitive_activites_options = [
  'Adult Entertainment Industry',
  'Exotic animals',
  'Military & Arms',
  'Gambling & Sports',
  'Diamond Industry',
  'None of the above',
];
const sensitive_countries =
  'Please indicate if your activities are linked to the following countries';
const sensitive_countries_options = [
  'Afghanistan',
  'Belarus',
  'Cuba',
  'Iran',
  'Iraq',
  'North Korea',
  'Russia',
  'Syria',
  'Ukraine (Crimea and Donetsk, Kherson, Luhansk, and Zaporizhzhia regions)',
  'None of the above',
];
const business_jurisdictions =
  'Please select the main jurisdictions where your company conducts its business activities';
const service_provider = 'Does your company provide services to clients?';
const customer_jurisdictions =
  'Please select the jurisdictions where your clients are primarily based';

const validationSchema = Yup.object({
  [business_address]: Yup.string().optional(),
  [activity]: Yup.string().required().label('Your business activity'),
  [sensitive_activites]: Yup.array().min(1).label('This').required(),
  [sensitive_countries]: Yup.array().min(1).label('This').required(),
  [business_jurisdictions]: Yup.array().min(1).label('This').required(),
  [service_provider]: Yup.string()
    .oneOf(['Yes', 'No'])
    .required()
    .label('This'),
  [customer_jurisdictions]: Yup.array()
    .min(1)
    .label('This')
    .when(service_provider, {
      is: (val: string) => val === 'Yes',
      then: (schema) => schema.required(),
      otherwise: (schema) =>
        schema
          .optional()
          .nullable()
          .default(null)
          .transform(() => null),
    }),
});

export const CompanyAdditionalData = () => {
  const stepId = useStepId();
  const { t } = useTranslation();
  const { submitCustomStepForm } = useSubmitCustomStepForm();
  const { metadata } = useStore();

  const defaultValues = useMemo(() => {
    return {
      [business_address]: metadata?.[business_address] || '',
      [activity]: metadata?.[activity] || '',
      [sensitive_activites]: metadata?.[sensitive_activites] || '',
      [sensitive_countries]: metadata?.[sensitive_countries] || '',
      [business_jurisdictions]: metadata?.[business_jurisdictions] || '',
      [service_provider]: metadata?.[service_provider] || '',
      [customer_jurisdictions]: metadata?.[customer_jurisdictions] || '',
    };
  }, [metadata]);

  const methods = useForm<any>({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    resetField,
    formState: { isValid, isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<any> = async (formData) => {
    submitCustomStepForm({ caseMetadata: formData });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="6" alignItems="start">
        <GroupController
          name={business_address}
          label={t(`steps.${stepId}.${business_address}.label`)}
          helper={t(`steps.${stepId}.${business_address}.helper`)}
          isRequired={false}
          control={control}
          render={(f) => {
            return <Textarea maxW="400px" resize="vertical" {...f} />;
          }}
        />

        <GroupController
          name={activity}
          label={t(`steps.${stepId}.${activity}.label`)}
          helper={t(`steps.${stepId}.${activity}.helper`)}
          isRequired={true}
          control={control}
          render={(f) => {
            return <Textarea maxW="400px" resize="vertical" {...f} />;
          }}
        />

        <GroupController
          name={sensitive_activites}
          label={t(`steps.${stepId}.${sensitive_activites}.label`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Select
              stepId={stepId}
              name={sensitive_activites}
              onChange={(value: string) => {
                setValue(sensitive_activites, value ?? [], {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={sensitive_activites_options}
              defaultValue={f.value}
              hasOtherOption={false}
              isMulti={true}
            />
          )}
        />

        <GroupController
          name={sensitive_countries}
          label={t(`steps.${stepId}.${sensitive_countries}.label`)}
          helper={t(`steps.${stepId}.${sensitive_countries}.helper`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Select
              stepId={stepId}
              name={sensitive_countries}
              onChange={(value: string) => {
                setValue(sensitive_countries, value ?? [], {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={sensitive_countries_options}
              defaultValue={f.value}
              hasOtherOption={false}
              isMulti={true}
            />
          )}
        />

        <GroupController
          name={business_jurisdictions}
          label={t(`steps.${stepId}.${business_jurisdictions}.label`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <CountrySelect
              onChange={(value: string[]) => {
                setValue(business_jurisdictions, value ?? [], {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              defaultValue={f.value}
              isMulti={true}
            />
          )}
        />

        <GroupController
          name={service_provider}
          label={t(`steps.${stepId}.${service_provider}.label`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Radio
              stepId={stepId}
              name={service_provider}
              onChange={(value: string) => {
                resetField(customer_jurisdictions);
                setValue(service_provider, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={['Yes', 'No']}
              defaultValue={f.value}
            />
          )}
        />

        {watch(service_provider) === 'Yes' && (
          <GroupController
            name={customer_jurisdictions}
            label={t(`steps.${stepId}.${customer_jurisdictions}.label`)}
            isRequired={true}
            control={control}
            render={(f) => (
              <CountrySelect
                onChange={(value: string[]) => {
                  setValue(customer_jurisdictions, value ?? [], {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
                defaultValue={f.value}
                isMulti={true}
              />
            )}
          />
        )}

        <Box>
          <Button
            variant="next"
            isLoading={isSubmitting}
            isDisabled={!isValid}
            type="submit"
          >
            {t('domain.form.next')}
          </Button>
        </Box>
      </VStack>
    </form>
  );
};

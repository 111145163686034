import {
  BaseStepIdEnum,
  FieldPropertyTypeEnum,
  FieldTypeEnum,
  StepConfig,
  StepTypeEnum,
} from 'shared-domain';
import { UkFinancialPromotionRules } from '../app/components/uk-financial-promotion-rules';
import { CompanyAdditionalData } from '../app/components/company-additional-data';
import { IndividualAdditionalData } from '../app/components/individual-additional-data';
import { BusinessRelationship } from '../app/components/business-relationship';
import { AmlProgram } from '../app/components/aml-program';
import { WalletAddresses } from '../app/components/wallet-address';

export const stepsConfig: StepConfig[] = [
  {
    id: 'disclaimer',
    type: StepTypeEnum.text,
  },
  {
    id: 'disclaimer2',
    type: StepTypeEnum.text,
  },
  {
    id: 'disclaimer3',
    type: StepTypeEnum.text,
  },
  {
    id: 'are_you',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'are_you',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.checkbox,
          propertyType: FieldPropertyTypeEnum.metadata,
          options: ['An exchange platform', 'A token issuer'],
          hasOtherOption: true,
        },
      ],
    },
  },
  {
    id: 'uk_financial_promotion_rules',
    type: StepTypeEnum.custom_component,
    component: UkFinancialPromotionRules,
  },
  {
    id: BaseStepIdEnum.company_search,
  },
  {
    id: BaseStepIdEnum.company_list,
  },
  {
    id: BaseStepIdEnum.company_edit,
  },
  {
    id: 'company_additional_data',
    type: StepTypeEnum.custom_component,
    component: CompanyAdditionalData,
  },
  {
    id: BaseStepIdEnum.individuals_list,
    config: { hasApplicant: true },
  },
  {
    id: 'individual_additional_data',
    type: StepTypeEnum.custom_component,
    component: IndividualAdditionalData,
  },
  {
    id: 'business_relationship',
    type: StepTypeEnum.custom_component,
    component: BusinessRelationship,
  },
  {
    id: 'wallet_addresses',
    type: StepTypeEnum.custom_component,
    component: WalletAddresses,
  },
  {
    id: 'anti_money_laundering_program',
    type: StepTypeEnum.custom_component,
    component: AmlProgram,
  },
  {
    id: 'communicate_more_info',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'Applicant information',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.textarea,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'Contact person',
          isRequired: true,
          isEnabled: true,
          propertyType: FieldPropertyTypeEnum.metadata,
          type: FieldTypeEnum.textarea,
        },
        {
          id: 'additional_comment',
          isRequired: false,
          isEnabled: true,
          propertyType: FieldPropertyTypeEnum.metadata,
          type: FieldTypeEnum.textarea,
        },
      ],
    },
  },
  {
    id: 'pdf_viewer_terms',
    type: StepTypeEnum.terms_and_conditions,
    config: {
      pdfUrl:
        'https://keyrock.onboarding.dotfile.com/assets/terms-and-conditions.pdf',
    },
  },
];

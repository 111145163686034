import { useMemo } from 'react';
import { Box, Button, VStack, Textarea } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { GroupController, Radio } from 'frontend-components';
import { useStepId, useStore, useSubmitCustomStepForm } from 'frontend-common';

const AML_CFT_regulatory_requirements =
  'Please indicate whether your company is subject to AML/CFT regulatory requirements from an external supervisory body';
const types_of_activities_or_services =
  'Please specify the types of services or activities your company is regulated to perform';
const name_of_regulatory_authority =
  'Please provide the name of the regulatory authority that oversees your AML/CFT compliance';
const company_not_regulated_reasons =
  'Please elaborate on the reasons why your company is not regulated';

const validationSchema = Yup.object({
  [AML_CFT_regulatory_requirements]: Yup.string()
    .oneOf(['Yes', 'No'])
    .label('This field')
    .required(),
  [types_of_activities_or_services]: Yup.string().when(
    AML_CFT_regulatory_requirements,
    {
      is: (val: string) => val === 'Yes',
      then: (schema) => schema.label('This field').required(),
      otherwise: (schema) =>
        schema
          .optional()
          .nullable()
          .default(null)
          .transform(() => null),
    },
  ),
  [name_of_regulatory_authority]: Yup.string().when(
    AML_CFT_regulatory_requirements,
    {
      is: (val: string) => val === 'Yes',
      then: (schema) => schema.label('This field').required(),
      otherwise: (schema) =>
        schema
          .optional()
          .nullable()
          .default(null)
          .transform(() => null),
    },
  ),
  [company_not_regulated_reasons]: Yup.string().when(
    AML_CFT_regulatory_requirements,
    {
      is: (val: string) => val === 'No',
      then: (schema) => schema.label('This field').required(),
      otherwise: (schema) =>
        schema
          .optional()
          .nullable()
          .default(null)
          .transform(() => null),
    },
  ),
});

export const AmlProgram = () => {
  const stepId = useStepId();
  const { t } = useTranslation();
  const { submitCustomStepForm } = useSubmitCustomStepForm();
  const { metadata } = useStore();

  const defaultValues = useMemo(() => {
    return {
      [AML_CFT_regulatory_requirements]:
        metadata?.[AML_CFT_regulatory_requirements] || '',
      [types_of_activities_or_services]:
        metadata?.[types_of_activities_or_services] || '',
      [name_of_regulatory_authority]:
        metadata?.[name_of_regulatory_authority] || '',
      [company_not_regulated_reasons]:
        metadata?.[company_not_regulated_reasons] || '',
    };
  }, [metadata]);

  const methods = useForm<any>({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    resetField,
    formState: { isValid, isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<any> = async (formData) => {
    submitCustomStepForm({ caseMetadata: formData });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="6" alignItems="start">
        <GroupController
          name={AML_CFT_regulatory_requirements}
          label={t(`steps.${stepId}.${AML_CFT_regulatory_requirements}.label`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Radio
              stepId={stepId}
              name={AML_CFT_regulatory_requirements}
              onChange={(value: string) => {
                resetField(types_of_activities_or_services);
                resetField(name_of_regulatory_authority);
                resetField(company_not_regulated_reasons);
                setValue(AML_CFT_regulatory_requirements, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={['Yes', 'No']}
              defaultValue={f.value}
            />
          )}
        />

        {watch(AML_CFT_regulatory_requirements) === 'Yes' && (
          <>
            <GroupController
              name={types_of_activities_or_services}
              label={t(
                `steps.${stepId}.${types_of_activities_or_services}.label`,
              )}
              isRequired={true}
              control={control}
              render={(f) => {
                return <Textarea maxW="400px" resize="vertical" {...f} />;
              }}
            />
            <GroupController
              name={name_of_regulatory_authority}
              label={t(`steps.${stepId}.${name_of_regulatory_authority}.label`)}
              isRequired={true}
              control={control}
              render={(f) => {
                return <Textarea maxW="400px" resize="vertical" {...f} />;
              }}
            />
          </>
        )}

        {watch(AML_CFT_regulatory_requirements) === 'No' && (
          <GroupController
            name={company_not_regulated_reasons}
            label={t(`steps.${stepId}.${company_not_regulated_reasons}.label`)}
            helper={t(
              `steps.${stepId}.${company_not_regulated_reasons}.helper`,
            )}
            isRequired={true}
            control={control}
            render={(f) => {
              return <Textarea maxW="400px" resize="vertical" {...f} />;
            }}
          />
        )}

        <Box>
          <Button
            variant="next"
            isLoading={isSubmitting}
            isDisabled={!isValid}
            type="submit"
          >
            {t('domain.form.next')}
          </Button>
        </Box>
      </VStack>
    </form>
  );
};
